import { compose } from 'redux';
import { difference, cloneDeep, set, get } from 'lodash';

import { actionTypes } from '../actions/finances';
import { replaceAt, getLatest } from '../../utils';
import {
  FINANCES_TABS_KEYS,
  PAYMENT_METHOD_KEYS,
  ORDER_STATUS_KEYS,
  ORDER_DELIVERY_STATUS_KEYS,
  ORDER_PAYMENT_STATUS_KEYS,
} from '../../constants';

const getInitialTableState = () => ({
  isLoading: false,
  list: [],
  total: 0,
  offset: 0,
  limit: 100,
  editedRowId: null,
  expandedRowIds: [],
  editedRowDrafts: {},
  selectedRows: [],
  selectedAgentId: null,
  editedRowDraftsErrors: [],
  sorting: {
    field: 'updated_at',
    sort: 'desc',
  },
  filters: {
    search: '',
    deliveryStatus: '',
    agentId: '',
    status: [ORDER_STATUS_KEYS.OPEN, ORDER_STATUS_KEYS.PENDING],
  },
});

const initialState = {
  [FINANCES_TABS_KEYS.ORDERS]: getInitialTableState(),
  [FINANCES_TABS_KEYS.COMMISSIONS]: {
    ...getInitialTableState(),
    filters: {
      search: '',
      status: [],
      agentId: '',
      orderStatus: '',
    },
  },
  [FINANCES_TABS_KEYS.DOWNLINES]: {
    ...getInitialTableState(),
    filters: {
      search: '',
      status: [],
      agentId: '',
      orderStatus: '',
    },
  },
  [FINANCES_TABS_KEYS.RECEIPTS]: {
    ...getInitialTableState(),
    filters: {
      search: '',
      agentId: '',
      status: [],
    },
  },
  activeTab: FINANCES_TABS_KEYS.ORDERS,
};

const addLastDelivery = order => {
  const { order_deliveries } = order;

  order.lastDelivery = order_deliveries.length > 0 ? getLatest(order_deliveries) : {};
  return order;
};

const addActionFields = order => {
  const {
    checked_contract,
    checked_bank_docs,
    checked_bank_delivery,
    checked_payments,
    delivery_status,
    payment_status,
    status,
    dept,
    sum,
    payment_method,
  } = order;

  const checksReady =
    payment_method === PAYMENT_METHOD_KEYS.OMNICAS
      ? checked_contract && checked_bank_docs && checked_bank_delivery && checked_payments
      : checked_contract;

  order.canToggleStatus = checksReady && payment_status === ORDER_PAYMENT_STATUS_KEYS.PAID;

  order.canTogglePaymentStatus =
    status !== ORDER_STATUS_KEYS.CLOSED &&
    payment_status !== ORDER_PAYMENT_STATUS_KEYS.OPEN &&
    checked_contract;

  order.canBeCanceled = [ORDER_STATUS_KEYS.OPEN, ORDER_STATUS_KEYS.CANCELED].includes(status);

  order.canToggleOnHold = [
    ORDER_STATUS_KEYS.OPEN,
    ORDER_STATUS_KEYS.PENDING,
    ORDER_STATUS_KEYS.ON_HOLD,
  ].includes(status);

  return order;
};

const mapFn = data => compose(addLastDelivery, addActionFields)(data);

const processOrderList = data => {
  return data.map(item => mapFn(item));
};

const financesReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FINANCES_CHANGE_TAB: {
      const activeTab = action.tab;
      const oldTab = state.activeTab;
      const oldSearch = state[oldTab]?.filters?.search || '';
      return {
        ...state,
        activeTab,
        [activeTab]: {
          ...state[activeTab],
          filters: {
            ...state[activeTab].filters,
            search: oldSearch,
          },
        },
      };
    }
    case actionTypes.FINANCES_SELECT_ROW: {
      const { activeTab } = state;
      const selectedRows = action.payload;

      const selectedAgentId =
        selectedRows.length > 0
          ? state[activeTab].list.find(({ id }) => id === selectedRows[0])?.partner_id
          : null;

      return {
        ...state,
        [state.activeTab]: {
          ...state[activeTab],
          selectedRows: action.payload,
          selectedAgentId,
        },
      };
    }
    case actionTypes.FINANCES_UPDATE_ROW:
      const { activeTab } = state;
      const { list } = state[activeTab];
      const { order_id, data, path } = action;
      const rowIndex = list.findIndex(({ id }) => id === order_id);
      const updatedRow = set(cloneDeep(list[rowIndex]), path, data);

      return {
        ...state,
        [activeTab]: {
          ...state[activeTab],
          list: replaceAt(list, rowIndex, mapFn(updatedRow)),
        },
      };
    case actionTypes.FINANCES_CHANGE_FILTER: {
      const { activeTab } = state;
      return {
        ...state,
        [activeTab]: {
          ...state[activeTab],
          filters: {
            ...state[activeTab].filters,
            ...action.filter,
          },
        },
      };
    }
    case actionTypes.FINANCES_CHANGE_SORTING: {
      const { activeTab } = state;
      return {
        ...state,
        [activeTab]: {
          ...state[activeTab],
          sorting: action.sorting,
        },
      };
    }
    case actionTypes.FINANCES_CHANGE_PAGINATION: {
      const { activeTab } = state;
      return {
        ...state,
        [activeTab]: {
          ...state[activeTab],
          ...action.payload,
        },
      };
    }
    case actionTypes.GET_ORDERS_ASYNC_SUCCESS: {
      const { data: list, total, limit, offset } = action.payload;
      return {
        ...state,
        [FINANCES_TABS_KEYS.ORDERS]: {
          ...state[FINANCES_TABS_KEYS.ORDERS],
          isLoading: false,
          list: processOrderList(list),
          total,
          limit,
          offset,
        },
      };
    }
    case actionTypes.GET_COMMISSIONS_ASYNC_SUCCESS: {
      const { data: list, total, limit, offset } = action.payload;

      // use same action for 2 tabs, with fallback to COMMISSIONS
      const activeTab = [FINANCES_TABS_KEYS.COMMISSIONS, FINANCES_TABS_KEYS.DOWNLINES].includes(
        state.activeTab,
      )
        ? state.activeTab
        : FINANCES_TABS_KEYS.COMMISSIONS;

      return {
        ...state,
        [activeTab]: {
          ...state[activeTab],
          isLoading: false,
          list,
          total,
          limit,
          offset,
        },
      };
    }
    case actionTypes.GET_RECEIPTS_ASYNC_SUCCESS: {
      const { data: list, total, limit, offset } = action.payload;
      return {
        ...state,
        [FINANCES_TABS_KEYS.RECEIPTS]: {
          ...state[FINANCES_TABS_KEYS.RECEIPTS],
          isLoading: false,
          list,
          total,
          limit,
          offset,
        },
      };
    }
    case actionTypes.FINANCES_SET_EXPANDED_IDS: {
      const { activeTab } = state;
      return {
        ...state,
        [activeTab]: {
          ...state[activeTab],
          expandedRowIds: action.expandedIds,
        },
      };
    }
    case actionTypes.FINANCES_SET_EDITED_ROW: {
      const { activeTab } = state;
      return {
        ...state,
        [activeTab]: {
          ...state[activeTab],
          editedRowDrafts: {},
          editedRowDraftsErrors: {},
          editedRowId: state[activeTab].editedRowId === action.id ? null : action.id,
        },
      };
    }

    case actionTypes.FINANCES_CHANGE_DRAFTS: {
      const { activeTab } = state;
      const { data } = action;
      const { editedRowDrafts, editedRowDraftsErrors } = state[activeTab];

      return {
        ...state,
        [activeTab]: {
          ...state[activeTab],
          editedRowDrafts: {
            ...editedRowDrafts,
            ...action.data,
          },
          // remove errors for updated fields:
          editedRowDraftsErrors: difference(editedRowDraftsErrors, Object.keys(data)),
        },
      };
    }
    case actionTypes.GET_ORDERS_ASYNC_START:
    case actionTypes.GET_COMMISSIONS_ASYNC_START:
    case actionTypes.GET_RECEIPTS_ASYNC_START:
    case actionTypes.UPDATE_ORDER_ASYNC_START:
    case actionTypes.CREATE_ORDER_PAYMENT_START:
    case actionTypes.UPDATE_ORDER_PAYMENT_START:
    case actionTypes.DELETE_ORDER_PAYMENT_START:
    case actionTypes.CREATE_ORDER_PRODUCT_START:
    case actionTypes.UPDATE_ORDER_PRODUCT_START:
    case actionTypes.DELETE_ORDER_PRODUCT_START:
    case actionTypes.CREATE_ORDER_DELIVERY_START:
    case actionTypes.UPDATE_ORDER_DELIVERY_START:
    case actionTypes.DELETE_ORDER_DELIVERY_START:
    case actionTypes.UPDATE_RECEIPT_ASYNC_START:
    case actionTypes.DELETE_RECEIPT_ASYNC_START:
    case actionTypes.UPDATE_AGENT_COMMISSION_ASYNC_START:
    case actionTypes.REBUILD_RECEIPT_ASYNC_START: {
      return {
        ...state,
        [state.activeTab]: {
          ...state[state.activeTab],
          isLoading: true,
        },
      };
    }
    case actionTypes.UPDATE_AGENT_COMMISSION_ASYNC_SUCCESS: {
      const { activeTab } = state;
      const { payload } = action;
      const { list } = state[activeTab];

      const rowIndex = list.findIndex(item => item?.id === payload.id);
      const updatedList = replaceAt(list, rowIndex, payload);

      return {
        ...state,
        [activeTab]: {
          ...state[activeTab],
          list: updatedList,
          isLoading: false,
        },
      };
    }
    case actionTypes.UPDATE_ORDER_ASYNC_SUCCESS:
    case actionTypes.CREATE_ORDER_PAYMENT_SUCCESS:
    case actionTypes.UPDATE_ORDER_PAYMENT_SUCCESS:
    case actionTypes.DELETE_ORDER_PAYMENT_SUCCESS:
    case actionTypes.CREATE_ORDER_PRODUCT_SUCCESS:
    case actionTypes.UPDATE_ORDER_PRODUCT_SUCCESS:
    case actionTypes.DELETE_ORDER_PRODUCT_SUCCESS:
    case actionTypes.CREATE_ORDER_DELIVERY_SUCCESS:
    case actionTypes.UPDATE_ORDER_DELIVERY_SUCCESS:
    case actionTypes.DELETE_ORDER_DELIVERY_SUCCESS: {
      const { activeTab } = state;
      const { payload } = action;
      const { list } = state[activeTab];

      const path = activeTab === FINANCES_TABS_KEYS.ORDERS ? 'id' : 'order.id';
      const rowIndex = list.findIndex(item => get(item, path) === payload.id);
      const updatedList =
        activeTab === FINANCES_TABS_KEYS.ORDERS
          ? replaceAt(list, rowIndex, mapFn(payload))
          : replaceAt(list, rowIndex, set(list[rowIndex], 'order', payload));

      return {
        ...state,
        [activeTab]: {
          ...state[activeTab],
          list: updatedList,
          isLoading: false,
        },
      };
    }
    case actionTypes.REBUILD_RECEIPT_ASYNC_SUCCESS:
    case actionTypes.UPDATE_RECEIPT_ASYNC_SUCCESS: {
      const { payload } = action;
      const { list } = state[FINANCES_TABS_KEYS.RECEIPTS];

      const rowIndex = list.findIndex(({ id }) => id === payload.id);
      const updatedList = replaceAt(list, rowIndex, payload);

      return {
        ...state,
        [FINANCES_TABS_KEYS.RECEIPTS]: {
          ...state[FINANCES_TABS_KEYS.RECEIPTS],
          list: updatedList,
          isLoading: false,
        },
      };
    }
    case actionTypes.DELETE_RECEIPT_ASYNC_SUCCESS: {
      const { payload: receiptId } = action;
      const { list } = state[FINANCES_TABS_KEYS.RECEIPTS];

      const updatedList = list.filter(({ id }) => id !== receiptId);

      return {
        ...state,
        [FINANCES_TABS_KEYS.RECEIPTS]: {
          ...state[FINANCES_TABS_KEYS.RECEIPTS],
          list: updatedList,
          isLoading: false,
        },
      };
    }
    case actionTypes.GET_ORDERS_ASYNC_ERROR:
    case actionTypes.GET_COMMISSIONS_ASYNC_ERROR:
    case actionTypes.GET_RECEIPTS_ASYNC_ERROR:
    case actionTypes.CREATE_ORDER_PRODUCT_ERROR:
    case actionTypes.UPDATE_ORDER_PRODUCT_ERROR:
    case actionTypes.DELETE_ORDER_PRODUCT_ERROR:
    case actionTypes.CREATE_ORDER_DELIVERY_ERROR:
    case actionTypes.UPDATE_ORDER_DELIVERY_ERROR:
    case actionTypes.DELETE_ORDER_DELIVERY_ERROR:
    case actionTypes.CREATE_ORDER_PAYMENT_ERROR:
    case actionTypes.UPDATE_ORDER_PAYMENT_ERROR:
    case actionTypes.DELETE_ORDER_PAYMENT_ERROR:
    case actionTypes.UPDATE_RECEIPT_ASYNC_ERROR:
    case actionTypes.DELETE_RECEIPT_ASYNC_ERROR:
    case actionTypes.UPDATE_AGENT_COMMISSION_ASYNC_ERROR:
    case actionTypes.REBUILD_RECEIPT_ASYNC_ERROR: {
      return {
        ...state,
        [state.activeTab]: {
          ...state[state.activeTab],
          isLoading: false,
        },
      };
    }
    case actionTypes.CREATE_RECEIPT_ASYNC_START: {
      return {
        ...state,
        [FINANCES_TABS_KEYS.COMMISSIONS]: {
          ...state[FINANCES_TABS_KEYS.COMMISSIONS],
          selectedRows: [],
          isLoading: true,
        },
      };
    }
    case actionTypes.CREATE_RECEIPT_ASYNC_SUCCESS:
    case actionTypes.CREATE_RECEIPT_ASYNC_ERROR: {
      return {
        ...state,
        [FINANCES_TABS_KEYS.COMMISSIONS]: {
          ...state[FINANCES_TABS_KEYS.COMMISSIONS],
          isLoading: false,
        },
      };
    }
    default:
      return state;
  }
};

export default financesReducer;
