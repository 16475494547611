import { get, set } from 'lodash';

import { actionTypes } from '../actions/clients';
import { replaceAt } from '../../utils';
import { CLIENTS_TABLE_NAME_KEYS, EXT_ROW_NESTED_TAB_KEYS } from '../../constants';

const DEFAULT_CUSTOM_FILTER = {
  name: '',
  type: null,
  value: '',
  errors: {
    field: '',
    value: '',
  },
};

const initialState = {
  currentTable: CLIENTS_TABLE_NAME_KEYS.B2C,
  aircallEvent: null,
  clientUpdatedEvent: {
    lastUpdated: {},
    dialogOpen: false,
  },
  BUYERS: {
    extendedIds: [],
    isLoading: false,
    filters: {
      visible: true,
      preset: {
        list: [],
        search: '',
      },
      sorted: [],
    },
    limit: 100,
    offset: 0,
    total: 0,
    data: [],
    error: null,
    extendedRowData: null,
  },
  B2C: {
    extendedIds: [],
    isLoading: false,
    filters: {
      visible: true,
      preset: {
        statuses: [],
        list: [],
        zipcode: '',
        search: '',
      },
      /**
       * {Object[]} custom - array which represent custom filters:
       * custom[].name - {string} - field name
       * custom[].type - {string} - one of: TEXT, ENUM, DATE, BOOL
       * custom[].value - {string|boolean|object*} - field value
       * *object in case of DATE type: { from: 'ISOdate', to: 'ISOdate' }
       */
      custom: [],
    },
    data: [],
    limit: 100,
    offset: 0,
    total: 0,
    error: null,
    isNestedTabsLoading: false,
    extendedRowNestedTab: EXT_ROW_NESTED_TAB_KEYS.RECOMMENDATIONS,
    extendedRowData: null,
    selectedRows: [],
    sorting: {
      field: 'updated_at',
      sort: 'desc',
    },
  },
  B2B: {
    extendedIds: [],
    isLoading: false,
    filters: {
      visible: true,
      preset: {
        statuses: [],
        list: [],
        zipcode: '',
        search: '',
      },
      sorted: [],
      /**
       * {Object[]} custom - array which represent custom filters:
       * custom[].name - {string} - field name
       * custom[].type - {string} - one of: TEXT, ENUM, DATE, BOOL
       * custom[].value - {string|boolean|object*} - field value
       * object in case of DATE type: { from: 'ISOdate', to: 'ISOdate' }
       */
      custom: [],
    },
    data: [],
    limit: 100,
    offset: 0,
    total: 0,
    error: null,
    isNestedTabsLoading: false,
    extendedRowNestedTab: EXT_ROW_NESTED_TAB_KEYS.RECOMMENDATIONS,
    extendedRowData: null,
    selectedRows: [],
    sorting: {
      field: 'updated_at',
      sort: 'desc',
    },
  },
  LISTING: {
    extendedIds: [],
    isLoading: false,
    data: [],
    error: null,
    extendedRowData: null,
  },
};

const clientsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_EXTENDED_ROW_IDS: {
      const { extendedIds } = action;
      const { currentTable } = state;
      return {
        ...state,
        [currentTable]: {
          ...state[currentTable],
          extendedIds,
        },
      };
    }
    case actionTypes.GET_NESTED_TABS_DATA_ASYNC_START: {
      const { currentTable } = state;
      return {
        ...state,
        [currentTable]: {
          ...state[currentTable],
          isNestedTabsLoading: true,
        },
      };
    }
    case actionTypes.GET_NESTED_TABS_DATA_ASYNC_SUCCESS: {
      const { currentTable } = state;
      return {
        ...state,
        [currentTable]: {
          ...state[currentTable],
          isNestedTabsLoading: false,
          extendedRowNestedTab:
            action.payload?.recommendations?.length > 0
              ? EXT_ROW_NESTED_TAB_KEYS.RECOMMENDATIONS
              : action.payload?.duplicates?.length > 0
              ? EXT_ROW_NESTED_TAB_KEYS.DUPLICATES
              : action.payload?.cohabitants?.length > 0
              ? EXT_ROW_NESTED_TAB_KEYS.COHABITANTS
              : EXT_ROW_NESTED_TAB_KEYS.BLOCK_NEIGHBORS,
          extendedRowData: {
            ...(state[currentTable]?.extendedRowData || {}),
            ...action.payload,
          },
        },
      };
    }
    case actionTypes.GET_NESTED_TABS_DATA_ASYNC_ERROR: {
      const { currentTable } = state;
      return {
        ...state,
        [currentTable]: {
          ...state[currentTable],
          isNestedTabsLoading: false,
        },
      };
    }
    case actionTypes.CHANGE_NESTED_TAB: {
      const { tab } = action;
      const { currentTable } = state;
      return {
        ...state,
        [currentTable]: {
          ...state[currentTable],
          extendedRowNestedTab: tab,
        },
      };
    }
    case actionTypes.SET_DEFAULT_FILTERS: {
      const { filters } = action;
      const { currentTable } = state;
      return {
        ...state,
        [currentTable]: {
          ...state[currentTable],
          filters: {
            ...state[currentTable]?.filters,
            preset: {
              ...state[currentTable]?.filters?.preset,
              ...filters,
            },
          },
        },
      };
    }
    case actionTypes.GET_COHABITANTS_ASYNC_SUCCESS: {
      const { currentTable } = state;
      return {
        ...state,
        [currentTable]: {
          ...state[currentTable],
          extendedRowData: {
            ...(state[currentTable]?.extendedRowData || {}),
            cohabitants: action.payload,
          },
          isLoading: false,
        },
      };
    }
    case actionTypes.GET_BLOCK_NEIGHBORS_ASYNC_SUCCESS: {
      const { currentTable } = state;
      return {
        ...state,
        [currentTable]: {
          ...state[currentTable],
          extendedRowData: {
            ...(state[currentTable]?.extendedRowData || {}),
            blockNeighbors: action.payload,
          },
          isLoading: false,
        },
      };
    }
    case actionTypes.GET_DUPLICATES_ASYNC_SUCCESS: {
      const { currentTable } = state;
      return {
        ...state,
        [currentTable]: {
          ...state[currentTable],
          extendedRowData: {
            ...(state[currentTable]?.extendedRowData || {}),
            duplicates: action.payload,
          },
          isLoading: false,
        },
      };
    }
    case actionTypes.ADD_CUSTOM_FILTER: {
      const { currentTable } = state;
      return {
        ...state,
        [currentTable]: {
          ...state[currentTable],
          filters: {
            ...state[currentTable].filters,
            custom: [...state[currentTable].filters.custom, DEFAULT_CUSTOM_FILTER],
          },
        },
      };
    }
    case actionTypes.CHANGE_CUSTOM_FILTER: {
      const { filter } = action;
      const { currentTable } = state;
      const {
        filters: { custom: oldData },
      } = state[currentTable];
      const foundIndex = oldData.findIndex(({ name }) => name === filter.name);
      const index = foundIndex !== -1 ? foundIndex : oldData.length - 1;
      return {
        ...state,
        [currentTable]: {
          ...state[currentTable],
          filters: {
            ...state[currentTable].filters,
            custom: replaceAt(oldData, index, filter),
          },
        },
      };
    }
    case actionTypes.ADD_CUSTOM_FILTER_ERRORS: {
      const { custom } = action;
      const { currentTable } = state;
      return {
        ...state,
        [currentTable]: {
          ...state[currentTable],
          filters: {
            ...state[currentTable].filters,
            custom,
          },
        },
      };
    }
    case actionTypes.DELETE_CUSTOM_FILTER: {
      const { index } = action;
      const { currentTable } = state;
      const {
        filters: { custom: oldData },
      } = state[currentTable];
      return {
        ...state,
        [currentTable]: {
          ...state[currentTable],
          filters: {
            ...state[currentTable].filters,
            custom: oldData.filter((filter, i) => i !== index),
          },
        },
      };
    }
    case actionTypes.CLIENTS_SELECT_ROWS: {
      const { currentTable } = state;
      const selectedRows = action.payload;

      return {
        ...state,
        [currentTable]: {
          ...state[currentTable],
          selectedRows,
        },
      };
    }
    case actionTypes.CLIENTS_CHANGE_SORTING: {
      const { currentTable } = state;
      return {
        ...state,
        [currentTable]: {
          ...state[currentTable],
          sorting: action.sorting,
        },
      };
    }
    case actionTypes.CLIENTS_CHANGE_PAGINATION: {
      const { currentTable } = state;
      return {
        ...state,
        [currentTable]: {
          ...state[currentTable],
          ...action.payload,
        },
      };
    }
    case actionTypes.SET_AIRCALL_EVENT: {
      return {
        ...state,
        aircallEvent: action.payload,
      };
    }

    case actionTypes.PROCESS_CLIENT_UPDATED_EVENT: {
      const {
        payload: { client, index },
      } = action;
      const { currentTable } = state;
      const { data, extendedRowData } = state[currentTable];

      return {
        ...state,
        clientUpdatedEvent: {
          lastUpdated: client,
          dialogOpen: false, // set to true for triggering dialog
        },
        [currentTable]: {
          ...state[currentTable],
          data: replaceAt(data, index, client),
          ...(extendedRowData?.id === client.id && { extendedRowData: client }),
        },
      };
    }

    case actionTypes.PROCESS_LISTING_UPDATED_EVENT: {
      const {
        payload: { deleted_id, inserted },
      } = action;

      const { currentTable } = state;
      if (currentTable !== CLIENTS_TABLE_NAME_KEYS.LISTING) return state;

      const { data } = state[currentTable];
      const updatedList = data.filter(({ id }) => id !== deleted_id);
      if (inserted) updatedList.push(inserted);
      const [firstEl] = updatedList;

      return {
        ...state,
        [currentTable]: {
          ...state[currentTable],
          data: updatedList,
          extendedIds: firstEl ? [firstEl?.id] : [],
          extendedRowData: firstEl || null,
        },
      };
    }

    case actionTypes.PROCESS_CALL_ENDED_EVENT: {
      const {
        payload: { client, index },
      } = action;
      const { currentTable } = state;
      const { data, extendedRowData } = state[currentTable];

      return {
        ...state,
        [currentTable]: {
          ...state[currentTable],
          data: replaceAt(data, index, client),
          ...(extendedRowData?.id === client.id && { extendedRowData: client }),
        },
      };
    }

    case actionTypes.RESET_CLIENT_UPDATED_DIALOG: {
      return {
        ...state,
        clientUpdatedEvent: {
          ...state.clientUpdatedEvent,
          dialogOpen: false,
        },
      };
    }

    case actionTypes.RESET_EXTENDED_ROW_DATA: {
      const { currentTable } = state;
      return {
        ...state,
        [currentTable]: {
          ...state[currentTable],
          extendedIds: [],
          extendedRowData: null,
        },
      };
    }
    case actionTypes.GET_DUPLICATES_ASYNC_START:
    case actionTypes.MERGE_DUPLICATE_ASYNC_START:
    case actionTypes.GET_COHABITANTS_ASYNC_START:
    case actionTypes.GET_BLOCK_NEIGHBORS_ASYNC_START:
    case actionTypes.LINK_BLOCK_NEIGHBORS_ASYNC_START:
    case actionTypes.UPDATE_LINKED_AGENTS_ASYNC_START:
    case actionTypes.GET_DATA_ASYNC_START: {
      const { currentTable } = state;
      return {
        ...state,
        [currentTable]: {
          ...state[currentTable],
          isLoading: true,
        },
      };
    }
    case actionTypes.GET_DATA_ASYNC_SUCCESS: {
      const {
        payload: { data, offset, total, limit, tableName },
      } = action;
      return {
        ...state,
        [tableName]: {
          ...state[tableName],
          isLoading: false,
          data,
          total,
          offset,
          limit,
          ...(data.length > 0 &&
            tableName === CLIENTS_TABLE_NAME_KEYS.LISTING && {
              extendedRowData: data[0],
              extendedIds: [data[0].id],
            }),
        },
      };
    }
    case actionTypes.GET_DATA_ASYNC_ERROR: {
      const error = get(action.payload, 'error', 'Unknown error');
      const { currentTable } = state;
      return {
        ...state,
        [currentTable]: {
          ...state[currentTable],
          isLoading: false,
          data: [],
          error,
        },
      };
    }
    case actionTypes.GET_EXT_ROW_DATA: {
      const { rowId } = action;
      const { currentTable } = state;
      const data = state[currentTable]?.data;
      const extendedRowData = data.find(({ id }) => id === rowId);

      if (currentTable === CLIENTS_TABLE_NAME_KEYS.BUYERS) {
        extendedRowData.contact_history = extendedRowData.user?.contact_history;
      }

      return {
        ...state,
        [currentTable]: {
          ...state[currentTable],
          extendedRowData,
          extendedRowNestedTab: EXT_ROW_NESTED_TAB_KEYS.RECOMMENDATIONS,
        },
      };
    }
    case actionTypes.UPDATE_ROW_DATA: {
      const { data: updatedData, rowId, path } = action;
      const { currentTable } = state;
      const { extendedRowData, data } = state[currentTable];

      const rowIndex = data.findIndex(({ id }) => id === rowId);
      if (rowIndex === -1) {
        console.error('Unable to find row index');
        return state;
      }

      const updRow = path ? set(data[rowIndex], path, updatedData) : updatedData;

      return {
        ...state,
        [currentTable]: {
          ...state[currentTable],
          data: replaceAt(data, rowIndex, updRow),
          ...(extendedRowData?.id === rowId && { extendedRowData: updRow }),
        },
      };
    }
    case actionTypes.UPDATE_NESTED_ROW_DATA: {
      const { data: updatedData, rowId, path } = action;
      const { currentTable } = state;
      const { extendedRowData } = state[currentTable];
      const data = extendedRowData.recommendations;

      const rowIndex = data.findIndex(({ id }) => id === rowId);
      if (rowIndex === -1) {
        console.error('Unable to find row index');
        return state;
      }

      const updRow = path ? set(data[rowIndex], path, updatedData) : updatedData;

      return {
        ...state,
        [currentTable]: {
          ...state[currentTable],
          extendedRowData: {
            ...extendedRowData,
            recommendations: replaceAt(data, rowIndex, updRow),
          },
        },
      };
    }
    case actionTypes.CREATE_EVENT_ASYNC_SUCCESS:
    case actionTypes.UPDATE_EVENT_ASYNC_SUCCESS:
    case actionTypes.DELETE_EVENT_ASYNC_SUCCESS:
    case actionTypes.EDIT_PRESENTATION_PRODUCTS_ASYNC_SUCCESS:
    case actionTypes.DELETE_PRESENTATION_PRODUCT_ASYNC_SUCCESS:
    case actionTypes.EDIT_HISTORY_ASYNC_SUCCESS:
    case actionTypes.ADD_HISTORY_ASYNC_SUCCESS: {
      const {
        payload: { data: updatedData },
      } = action;

      const { currentTable } = state;
      const { data, extendedRowData } = state[currentTable];

      const rowId = get(extendedRowData, 'id');
      if (!rowId) return state;

      const rowIndex = data.findIndex(({ id }) => id === rowId);
      if (rowIndex === -1) {
        console.error('Unable to find row index');
        return state;
      }
      const updRow = { ...data[rowIndex], ...updatedData };

      return {
        ...state,
        [currentTable]: {
          ...state[currentTable],
          isLoading: false,
          data: replaceAt(data, rowIndex, updRow),
          extendedRowData: {
            ...extendedRowData,
            ...updatedData,
          },
        },
      };
    }
    case actionTypes.MERGE_DUPLICATE_ASYNC_SUCCESS:
    case actionTypes.LINK_COHABITANT_ASYNC_SUCCESS:
    case actionTypes.LINK_BLOCK_NEIGHBORS_ASYNC_SUCCESS: {
      const {
        payload: { extClient, processedClient },
      } = action;
      const { currentTable } = state;
      const { data, extendedRowData } = state[currentTable];

      const rowIndex = data.findIndex(({ id }) => id === extClient?.id);
      if (rowIndex === -1) {
        console.error('Unable to find row index');
        return state;
      }

      // include processed client in updated list (if present)
      const processedIndex = data.findIndex(({ id }) => id === processedClient?.id);
      const list = processedClient === -1 ? data : replaceAt(data, processedIndex, processedClient);

      return {
        ...state,
        [currentTable]: {
          ...state[currentTable],
          isLoading: false,
          data: replaceAt(list, rowIndex, extendedRowData),
          extendedRowData: extClient,
        },
      };
    }
    case actionTypes.EDIT_ACCOUNT_ASYNC_SUCCESS: {
      const {
        payload: { data: account },
      } = action;
      const { currentTable } = state;
      const { data, extendedRowData } = state[currentTable];

      const rowId = get(extendedRowData, 'id');
      if (!rowId) return state;

      const rowIndex = data.findIndex(({ id }) => id === rowId);
      if (rowIndex === -1) {
        console.error('Unable to find row index');
        return state;
      }
      const updatedData = { user: { ...data[rowIndex].user, account } };
      const updRow = { ...data[rowIndex], ...updatedData };
      return {
        ...state,
        [currentTable]: {
          ...state[currentTable],
          data: replaceAt(data, rowIndex, updRow),
          extendedRowData: {
            ...extendedRowData,
            ...updatedData,
          },
        },
      };
    }
    case actionTypes.CREATE_EVENT_ASYNC_ERROR:
    case actionTypes.UPDATE_EVENT_ASYNC_ERROR:
    case actionTypes.DELETE_EVENT_ASYNC_ERROR:
    case actionTypes.EDIT_PRESENTATION_PRODUCTS_ASYNC_ERROR:
    case actionTypes.EDIT_ACCOUNT_ASYNC_ERROR:
    case actionTypes.EDIT_HISTORY_ASYNC_ERROR:
    case actionTypes.UPDATE_LINKED_AGENTS_ASYNC_ERROR:
    case actionTypes.MERGE_DUPLICATE_ASYNC_ERROR:
    case actionTypes.GET_DUPLICATES_ASYNC_ERROR:
    case actionTypes.GET_COHABITANTS_ASYNC_ERROR:
    case actionTypes.GET_BLOCK_NEIGHBORS_ASYNC_ERROR:
    case actionTypes.LINK_BLOCK_NEIGHBORS_ASYNC_ERROR:
    case actionTypes.ADD_HISTORY_ASYNC_ERROR: {
      const { currentTable } = state;
      return {
        ...state,
        [currentTable]: {
          ...state[currentTable],
          isLoading: false,
          error: action.error,
        },
      };
    }
    case actionTypes.UPDATE_LINKED_AGENTS_ASYNC_SUCCESS: {
      const { currentTable } = state;
      return {
        ...state,
        [currentTable]: {
          ...state[currentTable],
          isLoading: false,
        },
      };
    }
    case actionTypes.TOGGLE_FILTERS_VISIBILITY: {
      const { currentTable } = state;
      return {
        ...state,
        [currentTable]: {
          ...state[currentTable],
          filters: {
            ...state[currentTable].filters,
            visible: !state[currentTable].filters.visible,
          },
        },
      };
    }
    case actionTypes.CHANGE_CURRENT_TABLE: {
      return {
        ...state,
        currentTable: action.tableName,
      };
    }
    case actionTypes.CHANGE_PAGE: {
      const { currentTable } = state;
      return {
        ...state,
        [currentTable]: {
          ...state[currentTable],
          offset: action.offset,
        },
      };
    }
    case actionTypes.CHANGE_SORTED: {
      const { currentTable } = state;
      const { filters } = state[currentTable];

      return {
        ...state,
        [currentTable]: {
          ...state[currentTable],
          filters: {
            ...filters,
            sorted: action.sorted,
          },
        },
      };
    }
    case actionTypes.CHANGE_PRESET_FILTER: {
      const { currentTable } = state;
      const {
        filter: { name, value },
      } = action;
      const { filters } = state[currentTable];

      return {
        ...state,
        [currentTable]: {
          ...state[currentTable],
          filters: {
            ...filters,
            preset: {
              ...filters.preset,
              [name]: value,
            },
          },
        },
      };
    }
    case actionTypes.CLIENTS_IS_LOADING: {
      const { currentTable } = state;
      const { isLoading } = action;

      return {
        ...state,
        [currentTable]: {
          ...state[currentTable],
          isLoading,
        },
      };
    }
    default:
      return state;
  }
};

export default clientsReducer;
