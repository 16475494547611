import { cloneDeep } from 'lodash';

import { actionTypes } from '../actions/import';
import { replaceAt } from '../../utils';
import {
  IMPORT_TYPE,
  OCR_TERMINATION_STATUS,
  OCR_REVIEW_STATUS_KEYS,
  OCR_DUPLICATION_STATUS_KEYS,
} from '../../constants';

const initialState = {
  selectedIds: [],
  isLaunchDialogOpen: false,
  isReloadDialogOpen: false,
  isLoading: false,
  importType: IMPORT_TYPE.OCR,
  data: [],
  files: [],
  limit: 50,
  offset: 0,
  total: 0,
  sorting: {
    order: 'desc',
    column: 'location',
  },
  filters: {
    location: '',
    reviewStatus: OCR_REVIEW_STATUS_KEYS.ALL,
    duplicationStatus: OCR_DUPLICATION_STATUS_KEYS.ALL,
  },
  error: '',
  locationCode: '',
  activeStep: 0,
  lastOCROperation: null,
  duplicatesInDB: {
    email: [],
    phone: [],
    mobile_phone: [],
    address: [],
  },
};

const importReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_FILTERS: {
      return {
        ...state,
        offset: 0,
        filters: {
          ...state.filters,
          ...action.filters,
        },
      };
    }
    case actionTypes.CHANGE_IMPORT_TYPE: {
      return {
        ...initialState,
        importType: action.importType,
      };
    }
    case actionTypes.CHANGE_LOCATION_CODE: {
      return {
        ...state,
        locationCode: action.code,
      };
    }
    case actionTypes.CHANGE_FILES: {
      return {
        ...state,
        files: action.files,
      };
    }
    case actionTypes.CHANGE_STEP: {
      return {
        ...state,
        activeStep: action.nextStep,
      };
    }
    case actionTypes.TOGGLE_IMPORT_POPOVER: {
      return {
        ...state,
        isOpen: !state.isOpen,
      };
    }
    case actionTypes.PARSE_OCR_IMPORTS_ASYNC_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case actionTypes.PREPARE_IMPORT_ASYNC_START: {
      return {
        ...state,
        isReloadDialogOpen: false,
        isLoading: true,
      };
    }
    case actionTypes.PARSE_OCR_IMPORTS_ASYNC_SUCCESS:
    case actionTypes.PARSE_OCR_IMPORTS_ASYNC_ERROR:
    case actionTypes.PREPARE_IMPORT_ASYNC_ERROR: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case actionTypes.PREPARE_IMPORT_ASYNC_SUCCESS: {
      const {
        payload: { data, duplicatesInDB, offset, limit, total },
        importType,
      } = action;
      // in case old import finished but user quickly changed tab
      if (importType !== state.importType) return state;

      return {
        ...state,
        isLoading: false,
        duplicatesInDB,
        data,
        ...(typeof offset === 'number' && { offset }),
        ...(typeof total === 'number' && { total }),
        ...(typeof limit === 'number' && { limit }),
      };
    }
    case actionTypes.UPDATE_ROW_ASYNC_SUCCESS: {
      const { payload } = action;
      const { data } = state;
      const index = data.findIndex(({ id }) => id === payload.id);
      return {
        ...state,
        data: replaceAt(data, index, payload),
      };
    }
    case actionTypes.BULK_UPDATE_ROWS: {
      const { data: updateData } = action;
      const { selectedIds, data } = state;

      if (selectedIds.length < 1) return state;

      const clonedData = cloneDeep(data);
      for (const selectedId of selectedIds) {
        const index = data.findIndex(({ id }) => id === selectedId);
        clonedData[index] = { ...clonedData[index], ...updateData };
      }

      return {
        ...state,
        data: clonedData,
      };
    }
    case actionTypes.LAUNCH_IMPORT_ASYNC_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case actionTypes.LAUNCH_IMPORT_ASYNC_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        data: [],
        files: [],
        selectedIds: [],
        isLaunchDialogOpen: false,
      };
    }
    case actionTypes.TOGGLE_LAUNCH_IMPORT_DIALOG: {
      const { flag } = action;
      return {
        ...state,
        isLaunchDialogOpen: flag,
      };
    }
    case actionTypes.TOGGLE_RELOAD_IMPORT_DIALOG: {
      const { flag } = action;
      return {
        ...state,
        isReloadDialogOpen: flag,
      };
    }
    case actionTypes.SET_SELECTED_IDS: {
      const { ids } = action;
      return {
        ...state,
        selectedIds: ids,
      };
    }
    case actionTypes.TOGGLE_LOADING: {
      const { isLoading } = action;
      return {
        ...state,
        isLoading,
      };
    }
    case actionTypes.START_OCR_IMPORT: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case actionTypes.OCR_POOLING_TICK:
    case actionTypes.OCR_POOLING_FINISHED: {
      const { payload } = action;

      return {
        ...state,
        lastOCROperation: !payload ? null : { ...payload },
        ...(payload.done && { activeStep: 0 }),
      };
    }
    // TODO OCR: how do we want to use it?
    case actionTypes.TERMINATE_RUNNING_OCR_ERROR:
    case actionTypes.OCR_POOLING_ERROR: {
      const { error } = action;
      return { ...state, error };
    }
    case actionTypes.CHANGE_SORTING: {
      const { sorting } = action;
      return {
        ...state,
        offset: 0,
        sorting,
      };
    }
    case actionTypes.TERMINATE_RUNNING_OCR_STARTED: {
      return {
        ...state,
        lastOCROperation: {
          ...state.lastOCROperation,
          terminationStatus: OCR_TERMINATION_STATUS.INITIATED,
        },
      };
    }
    case actionTypes.TERMINATE_RUNNING_OCR_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        lastOCROperation: payload,
      };
    }
    case actionTypes.SET_ROWS_PER_PAGE: {
      const { value } = action;
      return {
        ...state,
        limit: Number(value),
        offset: 0,
      };
    }
    case actionTypes.CHANGE_PAGE: {
      const { pageIndex } = action;
      const { limit } = state;
      return {
        ...state,
        offset: Number(pageIndex) * limit,
      };
    }
    default:
      return state;
  }
};

export default importReducer;
