import keyMirror from 'key-mirror';
import { get } from 'lodash';

import calendarActions from './calendar';
import snackbarActions from './snackbar';
import { EVENT_TYPE_KEYS } from '../../constants';

export const actionTypes = keyMirror({
  CHANGE_PERIOD: null,
  CHANGE_STEP: null,
  GET_PLANNER_PREVIEW_START: null,
  GET_PLANNER_PREVIEW_SUCCESS: null,
  GET_PLANNER_PREVIEW_ERROR: null,
  GET_PLANNER_DETAILS_START: null,
  GET_PLANNER_DETAILS_SUCCESS: null,
  GET_PLANNER_DETAILS_ERROR: null,
  GET_DETAILED_INFO_START: null,
  GET_DETAILED_INFO_SUCCESS: null,
  GET_DETAILED_INFO_ERROR: null,
  GET_USER_START: null,
  GET_USER_SUCCESS: null,
  GET_USER_ERROR: null,
  GET_EVENT_SUCCESS: null,
  GET_EVENT_ERROR: null,
  SET_SELECTED_AGENT: null,
  RESET_PREVIEW: null,
  GET_USER_PA_SUCCESS: null,
  GET_USER_PA_ERROR: null,
  CREATE_EVENT_PLANNER_START: null,
  CREATE_EVENT_PLANNER_SUCCESS: null,
  CREATE_EVENT_PLANNER_ERROR: null,
  TOGGLE_IS_EDITING: null,
  CHANGE_USER_DRAFTS: null,
  CHANGE_USER_ERRORS: null,
  UPDATE_USER_START: null,
  UPDATE_USER_SUCCESS: null,
  UPDATE_USER_ERROR: null,
  GET_BLOCKED_DATES_START: null,
  GET_BLOCKED_DATES_SUCCESS: null,
  GET_BLOCKED_DATES_ERROR: null,
  TOGGLE_FIXED_HEADER: null,
  TOGGLE_PREVIEW_FILTER: null,
  SET_SELECTED_PREVIEW_DAY: null,
  RESET_PLANNER_STATE: null,
});

export default {
  resetPlannerState: () => dispatch => dispatch({ type: actionTypes.RESET_PLANNER_STATE }),
  setSelectedPreviewDay: day => dispatch =>
    dispatch({ type: actionTypes.SET_SELECTED_PREVIEW_DAY, day }),
  togglePreviewFilter: filter => dispatch =>
    dispatch({ type: actionTypes.TOGGLE_PREVIEW_FILTER, filter }),
  toggleFixedHeader: () => dispatch => dispatch({ type: actionTypes.TOGGLE_FIXED_HEADER }),
  resetPreview: () => dispatch => dispatch({ type: actionTypes.RESET_PREVIEW }),
  setSelectedAgent: agent => dispatch => dispatch({ type: actionTypes.SET_SELECTED_AGENT, agent }),
  changeStep: step => dispatch => dispatch({ type: actionTypes.CHANGE_STEP, step }),
  changePeriod: data => dispatch => dispatch({ type: actionTypes.CHANGE_PERIOD, data }),
  toggleIsEditing: isEditing => dispatch =>
    dispatch({ type: actionTypes.TOGGLE_IS_EDITING, isEditing }),
  changeUserDrafts: data => dispatch => dispatch({ type: actionTypes.CHANGE_USER_DRAFTS, data }),
  changeUserErrors: errors => dispatch =>
    dispatch({ type: actionTypes.CHANGE_USER_ERRORS, errors }),
  updateUser: data => (dispatch, getState, api) => {
    dispatch({ type: actionTypes.UPDATE_USER_START });
    const { id } = getState().planner.user;
    return api.User.editUser(id, data).then(
      payload => dispatch({ type: actionTypes.UPDATE_USER_SUCCESS, payload }),
      error => {
        dispatch({ type: actionTypes.UPDATE_USER_ERROR, error });
        const message = JSON.stringify(error?.error || error?.message || error);
        dispatch(snackbarActions.openSnackbar(message));
      },
    );
  },
  getUser: id => (dispatch, getState, api) => {
    dispatch({ type: actionTypes.GET_USER_START });
    return api.User.getUser(id).then(
      payload => dispatch({ type: actionTypes.GET_USER_SUCCESS, payload }),
      error => dispatch({ type: actionTypes.GET_USER_ERROR, error }),
    );
  },
  getEvent: params => (dispatch, getState, api) => {
    return api.Event.getEvent(params).then(
      payload =>
        dispatch({
          type: actionTypes.GET_EVENT_SUCCESS,
          payload,
          eventType: params.eventType,
        }),
      error => dispatch({ type: actionTypes.GET_EVENT_ERROR, error }),
    );
  },
  getUserPrimaryAgents: id => (dispatch, getState, api) => {
    return api.User.getUserPrimaryAgents(id).then(
      payload => dispatch({ type: actionTypes.GET_USER_PA_SUCCESS, payload }),
      error => dispatch({ type: actionTypes.GET_USER_PA_ERROR, error }),
    );
  },

  getPreview: (params, options) => (dispatch, getState, api) => {
    dispatch({ type: actionTypes.GET_PLANNER_PREVIEW_START });

    params.destination = getState().addressValidation?.validationResult?.formattedAddress;

    const calculateForId = JSON.parse(window.localStorage.getItem('calculateForId'));
    if (calculateForId) params.calculateForId = calculateForId;

    return api.Planner.getPreview(params).then(
      payload => {
        return dispatch({
          type: actionTypes.GET_PLANNER_PREVIEW_SUCCESS,
          payload,
          date: params.date,
          purge: options?.stopPreviewLoadingRef?.current,
        });
      },
      error => dispatch({ type: actionTypes.GET_PLANNER_PREVIEW_ERROR, error }),
    );
  },
  getBlockedDates: params => (dispatch, getState, api) => {
    dispatch({ type: actionTypes.GET_BLOCKED_DATES_START });

    const calculateForId = JSON.parse(window.localStorage.getItem('calculateForId'));
    if (calculateForId) params.calculateForId = calculateForId;

    return api.Planner.getBlockedDatesForMonth(params).then(
      payload =>
        dispatch({ type: actionTypes.GET_BLOCKED_DATES_SUCCESS, payload, month: params.month }),
      error => dispatch({ type: actionTypes.GET_BLOCKED_DATES_ERROR, error }),
    );
  },
  getDetails: params => (dispatch, getState, api) => {
    const {
      addressValidation: {
        validationResult: { formattedAddress },
      },
    } = getState();
    params.destination = formattedAddress;
    const calculateForId = JSON.parse(window.localStorage.getItem('calculateForId'));
    if (calculateForId) params.calculateForId = calculateForId;

    dispatch({ type: actionTypes.GET_PLANNER_DETAILS_START });

    return api.Planner.getDetails(params).then(
      payload =>
        dispatch({
          type: actionTypes.GET_PLANNER_DETAILS_SUCCESS,
          payload,
          timestamp: params.timestamp,
        }),
      error => dispatch({ type: actionTypes.GET_PLANNER_DETAILS_ERROR, error }),
    );
  },
  createEvent: (params, type, serviceSourceId) => async (dispatch, getState, api) => {
    dispatch({ type: actionTypes.CREATE_EVENT_PLANNER_START });

    const { date, agent_id, user_id } = params;

    try {
      const payload = await (type === EVENT_TYPE_KEYS.PRESENTATION
        ? api.Presentation.createPresentation({ date, agent_id, user_id })
        : api.Clients.addService(serviceSourceId, params));

      const { presentation_id, service_id, data } = payload;
      dispatch({
        type: actionTypes.CREATE_EVENT_PLANNER_SUCCESS,
        ...(type === EVENT_TYPE_KEYS.PRESENTATION ? { presentation_id } : { service_id }),
      });

      const userData = data?.user || data;

      dispatch(
        calendarActions.openDialog(
          {
            eventType: type,
            ...(service_id && { serviceId: service_id, presentationId: serviceSourceId }),
            ...(presentation_id && { presentationId: presentation_id }),
            userData,
            agentId: agent_id,
            date,
          },
          'create',
        ),
      );

      return payload;
    } catch (error) {
      dispatch({ type: actionTypes.CREATE_EVENT_PLANNER_ERROR, error });
      const message = JSON.stringify(error?.error || error?.message || error);
      dispatch(snackbarActions.openSnackbar(message));
    }
  },
  moveEvent: (data, type) => async (dispatch, getState, api) => {
    const { calendar_event_id, status, date, presentation_id, service_id } = data;

    try {
      await (type === EVENT_TYPE_KEYS.PRESENTATION
        ? api.Presentation.updatePresentation(presentation_id, {
            data: { status },
          })
        : api.Clients.updateService(service_id, { status }));
      const payload = await api.Calendar.moveEvent({
        eventId: calendar_event_id,
        status,
        date,
      });
      const summary = payload.data?.summary;
      const error = payload.data?.error;
      if (error) {
        dispatch(
          snackbarActions.openSnackbar('cmp.snackbar.calendar_event_error', 'error', {
            error: error?.message || error.toString(),
          }),
        );
      } else {
        dispatch(
          snackbarActions.openSnackbar('cmp.snackbar.calendar_event_updated_success', 'success', {
            summary,
          }),
        );
      }
    } catch (error) {
      const message = get(error, 'data.error.message', 'cmp.snackbar.calendar_event_error');
      dispatch(snackbarActions.openSnackbar(message));
    }
  },
};
